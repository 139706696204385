var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.folder.group.domainSettings")) +
                  " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.folder.group.domainName")) + " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "title", disabled: "" },
                model: {
                  value: _vm.groupName,
                  callback: function($$v) {
                    _vm.groupName = $$v
                  },
                  expression: "groupName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.folder.group.domainGroup")) + " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "group-domain", disabled: !_vm.isSysAdmin },
                on: { update: _vm.setGroupDomain },
                model: {
                  value: _vm.domain,
                  callback: function($$v) {
                    _vm.domain = $$v
                  },
                  expression: "domain"
                }
              }),
              _vm.showSaveButton
                ? _c(
                    "b-button",
                    { staticClass: "mt-3", on: { click: _vm.saveDomain } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("settings.folder.group.button")) +
                          " "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }