import gql from 'graphql-tag';

export default gql`
mutation updateGroupDomain(
  $groupId: Int!,
  $domain: String!
) {
  update_groups(where: {group_id: {_eq: $groupId}}, _set: {domain: $domain}) {
    affected_rows
  }
}
`;
